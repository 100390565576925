<template>
    <vx-card title="Handover Data View">
        <div class="vx-row mb-6" style="width: 50%" v-if="readonly">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Shipment Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="deliveryPlanNumber" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="readonly">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Handover Document</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="pickingListNumber" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="readonly">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Vehicle Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="pickingListNumber" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="readonly">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Driver</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="pickingListNumber" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Handover Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Date></Date>
            </div>
        </div>
        <vs-table stripe border :data="table.data">
            <template slot="thead">
                <vs-th sort-key="">Delivery Note Data</vs-th>
                <vs-th sort-key="">Sales Order Data</vs-th>
                <vs-th sort-key="">Customer Data</vs-th>
                <vs-th sort-key="">Ship-to Data</vs-th>
                <vs-th sort-key="">Request Delivery Date (RDD)</vs-th>
                <vs-th>COD</vs-th>
                <vs-th>Handed-over</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td></vs-td>
                    <vs-td></vs-td>
                    <vs-td></vs-td>
                    <vs-td></vs-td>
                    <vs-td></vs-td>
                    <vs-td>
                        <vs-checkbox
                            :checked="checked.includes(data[indextr].ID)"
                            v-on:click="addChecked(data[indextr].ID)"
                        ></vs-checkbox>
                    </vs-td>
                    <vs-td>
                        <vs-checkbox
                            :checked="checked.includes(data[indextr].ID)"
                            v-on:click="addChecked(data[indextr].ID)"
                        ></vs-checkbox>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <br>
        <div class="mb-6 space-x-1 flex">
            <div class="">
                <vs-button color="primary" @click="handleClose">Close</vs-button>
            </div>
        </div>
    </vx-card>
</template>

<script>

import Warehouse from "./component/Warehouse.vue";
import Date from "./component/Date.vue";
import moment from "moment";

export default {
    components: {
        Warehouse,
        Date,
    },
    props: {
        baseUrl: {
            type: String,
        },
        draw: {
            type: Number,
        },
    },
    data() {
        return {
            readonly: true,
            table: this.tableDefaultState(),
            deliveryPlanNumber: null,
            pickingListNumber: null,
            description: null,
            checkedAll: false,
            checked: [],
            skuLines: [
                {
                    option_sku: [],
                    selected_sku: null,
                    option_hu: [],
                    selected_hu: null,
                    qty: 0,
                }
            ]
        }
    },
    methods: {
        tableDefaultState() {
            return {
                data: [{}],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get(this.baseUrl + "/table", {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        search: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        // ...
                        date: moment(this.dateNow).format("YYYY-MM-DD"),
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.checked = [];
                        this.selectedRouteAssignmentToAssign = [];

                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleClose() {
            window.history.back();
        },
    },
    mounted() {
        this.getData();
    },
    watch: {
        detail() {
            this.getData();
        },
        checkedAll(val) {
            if (val) {
                this.selectedRouteAssignmentToAssign = [];
                this.table.data.forEach((el, index) => {
                    this.checked[index] = true;
                    this.selectedRouteAssignmentToAssign.push(el);
                });
            } else {
                if (
                    this.selectedRouteAssignmentToAssign.length == this.checked.length
                ) {
                    this.checked.forEach((el, index) => {
                        this.checked[index] = false;
                    });
                    this.selectedRouteAssignmentToAssign = [];
                }
            }
        },
    },
}
</script>